import { ref, computed } from "@vue/composition-api";

import { requestListBlogNews, requestSaveBlogNews, requestUpdateBlogNews, requestListAllOrgs } from "@/service/ListoService";

const blogList = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);
export const orgList = ref([]);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getBlogList = computed(() => blogList.value);
export const getAllOrgs = computed(() => orgList.value);

export const loadAllOrgs = async () => {
  try {
    loading.value = true;
    const {
      data: { organizations }
    } = await requestListAllOrgs();

    if (organizations) {
      orgList.value.splice(0, orgList.value.length);
      orgList.value.push(...organizations);
    } else {
      orgList.value.splice(0, orgList.value.length);
    }
  } finally {
    loading.value = false;
  }
};

export const onLoadAllBlogs = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { blogNews, pagination }
    } = await requestListBlogNews(reqData);

    if (blogNews) {
      blogList.value.splice(0, blogList.value.length);
      blogList.value.push(...blogNews);
    } else {
      blogList.value.splice(0, blogList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const cancelNewItem = async (id) => {
  var index = blogList.value.findIndex((i) => i.id === id);
  if (index !== -1) {
    blogList.value.splice(index, 1);
  }
};

export const addNewItem = async (data) => {
  blogList.value.unshift(data);
};

export const saveBlogNewsItem = async (data) => {
  try {
    loading.value = true;
    await requestSaveBlogNews(data);
    await onLoadAllBlogs({ reset: true });
  } finally {
    loading.value = false;
  }
};

export const toggleBlogSync = async (data) => {
  try {
    loading.value = true;
    await requestUpdateBlogNews(data);
  } finally {
    loading.value = false;
  }
};
