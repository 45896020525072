<template>
  <b-card no-body title="Blogs/News">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="900" :disabled="getLoading" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1">
          <b-button
            v-b-tooltip.hover
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            title="Add new item"
            @click="addNewBlogNews"
            :disabled="getLoading"
          >
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>

          <b-button v-b-tooltip.hover v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" title="Refresh data" class="btn-icon" @click="onLoadAllBlogs">
            <b-spinner v-if="getLoading" small />
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getBlogList" :current-page="currentPage">
        <template #cell(headline)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(isBlog)="data">
          <feather-icon icon="CheckCircleIcon" class="text-success mr-1" v-if="data.value" />
          <feather-icon icon="XCircleIcon" class="text-danger mr-1" v-if="!data.value" />
        </template>
        <template #cell(urgent)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleSync($event, data.item, 'urgent')" switch />
        </template>
        <template #cell(active)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleSync($event, data.item, 'active')" switch />
        </template>

        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <template v-if="editBlogNewsItem === row.item.id">
            <b-card :title="row.item.isBlog ? 'Blog Post' : 'News Article'" style="max-width: 80%" class="mb-2" bg-variant="light-secondary" border-variant="secondary">
              <b-card-text>
                <b-form>
                  <b-form-group id="input-group-1" label-for="input-imageurl" label-cols="2" label-align="right">
                    <template #label>
                      <b>Image URL:</b>
                    </template>
                    <b-form-input id="input-imageurl" maxlength="500" v-model="form.imageUrl" type="url" placeholder="Enter image url" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-cols="2" label-align="right">
                    <template #label>
                      <b>Article:</b>
                    </template>
                    <b-form-input id="input-articleurl" maxlength="500" v-model="form.articleUrl" type="url" placeholder="Enter article url" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-cols="2" label-align="right">
                    <template #label>
                      <b>Headline:</b>
                    </template>
                    <b-form-input id="input-headline" maxlength="100" v-model="form.headline" placeholder="Enter article headline" required></b-form-input>
                    <b-form-text id="input-live-help">Max 100 chars.</b-form-text>
                  </b-form-group>

                  <b-form-group id="input-group-5" label-cols="2" label-align="right">
                    <template #label>
                      <b>Summary:</b>
                    </template>
                    <b-form-textarea id="input-summary" maxlength="1000" rows="5" v-model="form.summary" placeholder="Enter article summary" required></b-form-textarea>
                  </b-form-group>
                  <template v-if="form.isNew">
                    <b-form-group label="Organization" label-cols="2" label-for="orgForItem" label-align="right">
                      <template #label>
                        <b>Organization:</b>
                      </template>
                      <b-form-select id="orgForItem" :value="form.defaultOrg" value-field="OrganizationId" text-field="name" v-model="form.orgObject" :options="getAllOrgs">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Please select an Organization --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </template>
                  <br />
                  <b-form-group id="input-toggles" label=" " label-cols="2" label-align="right">
                    <b-form-radio-group id="blog_news_group" v-model="form.isBlog">
                      <b-form-radio value="true">Blog Post?</b-form-radio>
                      <b-form-radio value="false">News Item?</b-form-radio>
                    </b-form-radio-group>
                    <br />
                    <b-form-checkbox v-model="form.urgent">Urgent?</b-form-checkbox>
                    <br />
                    <b-form-checkbox v-model="form.active">Active?</b-form-checkbox>
                  </b-form-group>
                  <br />
                  <b-button @click="saveEdited" variant="primary">Submit</b-button>
                  <b-button @click="cancelSave(row.item)" variant="danger">Cancel</b-button>
                </b-form>
              </b-card-text>
            </b-card>
          </template>
          <template v-else>
            <b-button id="edit_blog_news_button" class="btn-icon success" v-b-tooltip.hover variant="gradient-primary" title="Edit blog/news article" @click="editItem(row.item)">
              Edit
            </b-button>
            <br />
            <br />
            <template v-if="row.item.imageLink">
              <b>Image Link: </b> <b-link :href="row.item.imageLink" target="_blank">{{ row.item.imageLink.substring(row.item.imageLink.lastIndexOf("/") + 1) }}</b-link> <br />
            </template>
            <br />
            <template v-if="row.item.newsLink">
              <b>Article Link : </b>
              <b-link :href="row.item.newsLink" target="_blank">{{
                row.item.newsLink.length > 50 ? "..." + row.item.newsLink.substring(row.item.newsLink.length - 50) : row.item.newsLink
              }}</b-link>
              <p />
            </template>
            <br />
            <b-card
              :title="row.item.isBlog ? 'Blog Post' : 'News Article'"
              :img-src="row.item.imageLink"
              img-alt="Image"
              img-height="100%"
              img-width="150px"
              img-left
              bg-variant="light-secondary"
              border-variant="secondary"
              tag="article"
              style="max-width: 80%"
              class="mb-2"
            >
              <b-card-text>
                {{ row.item.newsBody }}
              </b-card-text>
            </b-card>

            <b>Item Id: </b>{{ row.item.blogNewsId }} <br />
            <br />
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getBlogList,
  filterBy,
  setPerPage,
  getLoading,
  onLoadAllBlogs,
  getTotalRows,
  getPerPage,
  saveBlogNewsItem,
  toggleBlogSync,
  addNewItem,
  cancelNewItem,
  loadAllOrgs,
  getAllOrgs
} from "@/views/listoconfig/blogRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllOrganizations",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const editBlogNewsItem = ref(-1);
    const DEFAULT_FORM = {
      headline: "",
      imageUrl: "",
      articleUrl: "",
      summary: "",
      isBlog: false,
      urgent: false,
      active: true,
      articleId: "",
      orgId: ""
    };

    let nextId = 99999;
    const form = DEFAULT_FORM;

    const fields = [
      // 'mark_resolved',
      "headline",
      { key: "isBlog", label: "Blog?", thClass: "text-center", tdClass: "text-center" },
      { key: "urgent", label: "Urgent?", thClass: "text-center", tdClass: "text-center" },
      { key: "active", label: "Active?", thClass: "text-center", tdClass: "text-center" },
      {
        key: "organizationName",
        label: "Organization"
      },
      "created"
    ];

    const editItem = (item) => {
      editBlogNewsItem.value = item.id;
      form.headline = item.headline;
      form.imageUrl = item.imageLink;
      form.articleUrl = item.newsLink;
      form.summary = item.newsBody;
      form.isBlog = item.isBlog;
      form.urgent = item.urgent;
      form.active = item.active;
      form.articleId = item.blogNewsId;
      form.orgId = item.organizationUid;
      form.isNew = false;
    };

    const saveEdited = async () => {
      let pd = {};
      pd.guuid = form.articleId;
      pd.headline = form.headline;
      pd.newsBody = form.summary;
      pd.newsLink = form.articleUrl;
      pd.imageLink = form.imageUrl;
      pd.urgent = form.urgent;
      pd.active = form.active;
      pd.isBlog = form.isBlog;
      pd.organizationUid = form.organizationUid;
      if (form.orgObject) {
        pd.organizationUid = form.orgObject;
      }
      try {
        await saveBlogNewsItem(pd);
      } finally {
        editBlogNewsItem.value = -1;
        form.headline = "";
        form.imageUrl = "";
        form.articleUrl = "";
        form.summary = "";
        form.isBlog = true;
        form.urgent = false;
        form.active = false;
        form.articleId = "";
        form.orgId = "";
        form.orgObject = null;
      }
    };

    watch(filterBy, (newValue, oldValue) => {
      initalLoadBlogs();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadBlogs = async () => onLoadAllBlogs({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadBlogs();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllBlogs({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllBlogs({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllBlogs({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    const addNewBlogNews = () => {
      let newData = DEFAULT_FORM;
      newData.headline = "New Item";
      newData.id = nextId;
      newData._showDetails = true;
      newData.isNew = true;
      editBlogNewsItem.value = newData.id;
      newData.orgObject = getAllOrgs.value ? getAllOrgs.value[0].OrganizationId : null;
      nextId++;
      addNewItem(newData);
    };

    const cancelSave = (data) => {
      if (data.isNew) {
        cancelNewItem(data.id);
      }
      editBlogNewsItem.value = -1;
    };

    const toggleSync = async (newVal, item, type) => {
      let req = {};
      req.forBlogUid = item.guuid;
      switch (type) {
        case "active":
          req.updateType = "ACTIVE";
          req.updateBoolean = newVal;
          break;
        case "urgent":
          req.updateType = "URGENT";
          req.updateBoolean = newVal;
          break;
        case "blogNews":
          req.updateType = "IS_BLOG";
          req.updateBoolean = newVal;
          break;
        case "headline":
          req.updateType = "HEADLINE";
          req.updateString = newVal;
          break;
      }
      try {
        await toggleBlogSync(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.message ? err.response.data.message : err.response.data.error ? err.response.data.error : "Server error";
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating dashboard news for ${item.headline} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await initalLoadBlogs();
      }
    };

    onMounted(async () => {
      filterBy.value = "";
      await loadAllOrgs();
    });

    return {
      getBlogList,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllBlogs,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      editBlogNewsItem,
      form,
      editItem,
      saveEdited,
      toggleSync,
      addNewBlogNews,
      cancelSave,
      getAllOrgs
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
.label {
  text-align: right;
}
</style>
