import axiosIns from "@/libs/axios";

export function requestLoadWelcomeMessage(reqData) {
  return axiosIns.post("/admin/load_welcomeMessages", reqData, {});
}

export function requestUpdateWelcomeMessage(reqData) {
  return axiosIns.post("/admin/create_edit_welcome", reqData, {});
}

export function requestListBlogNews(reqData) {
  return axiosIns.post("/admin/list_blognews", reqData, {});
}

export function requestSaveBlogNews(reqData) {
  return axiosIns.post("/admin/create_edit_blognews", reqData, {});
}

export function requestUpdateBlogNews(reqData) {
  return axiosIns.post("/admin/update_newsBlog", reqData, {});
}

export function requestListAllOrgs(reqData) {
  return axiosIns.post("/admin/list_allorganizations", reqData, {});
}

export function requestListTermsAndConditions(data) {
  return axiosIns.post("/admin/list_termsconditions", data, {});
}

export function requestChangeActiveTermsAndConditions(data) {
  return axiosIns.post("/admin/update_termsConditions", data, {});
}

export function requestAddNewTermsAndConditions(data) {
  return axiosIns.post("/admin/insert_termsConditions", data, {});
}

export function requestFlagWelcomeMessage(reqData) {
  return axiosIns.post("/admin/update_welcome_flags", reqData, {});
}
